import React, { useEffect, useState } from 'react';
import './EnterpriseAddInstructor.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInstructorById } from '../../store/enterpriseFeature/instructorEnterpriseByidSlice';
import { updateInstructorEnterprise } from '../../store/enterpriseFeature/updateinstructorEnterpriseSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Navigate } from 'react-router-dom';



const EnterpriseEditInstructor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  // Redux state
  const { loading, error, instructor } = useSelector(
    (state) => state.instructorEnterpriseByid
  );

  const { loading: updateLoading, error: updateError, success } = useSelector(
    (state) => state.updateInstructorEnterprise
  );

  // Local state for editable fields
  const [formData, setFormData] = useState({
    name: '',
    about_details: '',
    profile_image: '',
    courses_count: '',
    created_at: '',
    instructor_id: id, // Ensure it's instructor_id here
  });

  const [selectedImage, setSelectedImage] = useState(null);

  // Fetch instructor data
  useEffect(() => {
    if (id) {
      dispatch(fetchInstructorById(id));
    }
  }, [id, dispatch]);

  // Update local state when instructor data is loaded
  useEffect(() => {
    if (instructor?.data?.instructor) {
      const { name, about_details, profile_image, courses_count, created_at } =
        instructor.data.instructor;
      setFormData({
        name,
        about_details,
        profile_image,
        courses_count,
        created_at,
        instructor_id: id, // Ensure instructor_id is set properly
      });
    }
  }, [instructor, id]);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle image selection
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file)); // Preview selected image
      setFormData((prev) => ({
        ...prev,
        profile_image: file, // Update the profile image in formData
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Create a FormData object to handle the image file
    const updatedData = new FormData();
    updatedData.append('name', formData.name);
    updatedData.append('about_details', formData.about_details);
    updatedData.append('courses_count', formData.courses_count);
    updatedData.append('instructor_id', formData.instructor_id);  // Add the instructor_id here
  
    if (formData.profile_image) {
      updatedData.append('profile_image', formData.profile_image); // Append image if selected
    }
  
    // Dispatch the update action with the correct id and data
    dispatch(updateInstructorEnterprise(updatedData));
    navigate('/enterprise/home')
  };

  return (
    <form className="EnterpriseAddInstructor_Add_Form" onSubmit={handleSubmit}>
      <div className="EnterpriseAddInstructor_Container">
        <div className="EnterpriseAddInstructor_header">Edit Instructor Details</div>

        {/* Loading and error messages */}
        {loading && <p className="loading-message">Loading...</p>}
        {error && <p className="error-message">{error}</p>}

        {/* Editable instructor details */}
        {!loading && instructor && (
          <>
            <div className="EnterpriseAddInstructor_UploadImage">
              <div>Instructor Photo:</div>
              <div className="Upload_icon_section">
                {selectedImage ? (
                  <img
                    src={selectedImage}
                    alt="Selected"
                    className="uploaded_aadimage"
                  />
                ) : (
                  <img
                    src={formData.profile_image}
                    alt="Uploaded"
                    className="uploaded_aadimage"
                  />
                )}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="image-upload-input"
                />
              </div>
            </div>

            <div className="EnterpriseAddInstructor_Section">
              <div>Instructor Name</div>
              <input
                type="text"
                name="name"
                value={formData.name || ''}
                onChange={handleInputChange}
              />
            </div>

            <div className="EnterpriseAddInstructor_Section">
              <div>Instructor Bio</div>
              <textarea
                name="about_details"
                maxLength="295"
                value={formData.about_details || ''}
                onChange={handleInputChange}
              ></textarea>
            </div>

            <div className="EnterpriseAddInstructor_Section">
              <div>Created At</div>
              <input
                type="text"
                name="created_at"
                value={formData.created_at || ''}
                disabled
              />
            </div>

            <button type="submit" className="InstructorPage_SaveButton" disabled={updateLoading}>
              {updateLoading ? (
                <motion.div
                  className="spinner"
                  animate={{ rotate: 360 }}
                  transition={{
                    repeat: Infinity,
                    duration: 1,
                    ease: 'linear',
                  }}
                >
                  <motion.div
                    className="spinner-inner"
                    initial={{ scale: 0.8 }}
                    animate={{ scale: 1.2 }}
                    transition={{
                      repeat: Infinity,
                      duration: 0.5,
                      ease: 'easeInOut',
                    }}
                  />
                </motion.div>
              ) : (
                'Save Changes'
              )}
            </button>

            {/* Success/Failure Messages */}
            {success && <p className="success-message">Instructor updated successfully!</p>}
            {updateError && <p className="error-message">{updateError?.msg}</p>}
          </>
        )}
      </div>
    </form>
  );
};

export default EnterpriseEditInstructor;
