import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { instructorOtpChangePassword } from '../../../store/features/instructorOtpChangePasswordSlice';
import Footer from '../../../component/Footer/Footer';
import Logo from '../../../assets/images/lndexlogo_svg.svg';
import PasswordInput from '../../../component/PasswordInput/PasswordInput';

const ChangeOtpPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Get OTP code from navigation state
  const otpCode = location.state?.code;

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validationError, setValidationError] = useState('');

  // Access Redux state for feedback
  const { loading, message, error } = useSelector((state) => state.instructorOtpChangePassword);

  const handleSubmit = () => {
    if (newPassword !== confirmPassword) {
      setValidationError('Passwords do not match.');
      return;
    }

    // Clear validation error
    setValidationError('');

    // Dispatch the Redux action with the required data
    const data = {
      otp_code: otpCode,
      new_password: newPassword,
    };

    dispatch(instructorOtpChangePassword(data)).then(() => {
      // Navigate to /login after 3 seconds if the action succeeds
      if (!error && message) {
        setTimeout(() => {
          navigate('/login', { state: { activeRole: 'Instructor' } });

        }, 1000);
      }
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !loading && otpCode && newPassword && confirmPassword) {
      handleSubmit();
    }
  };

  return (
    <div className="ChangeOtpPassword_Container">
      <div className="ChangeOtpPassword_Sec1">
        <img src={Logo} alt="Logo" />

        <label>Please Enter New Password</label>
        <PasswordInput
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          onKeyDown={handleKeyDown} // Handle "Enter" key
          placeholder="Enter New Password"
          className="ChangeOtpPassword_Sec1_input"
        />

        <PasswordInput
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          onKeyDown={handleKeyDown} // Handle "Enter" key
          placeholder="Retype password"
          className="ChangeOtpPassword_Sec1_input"
        />

        <div className="Registration_body_Form_Sec5">
          <button
            className="Signup_body_Form_Sec5_button"
            onClick={handleSubmit}
            disabled={loading || !otpCode || !newPassword || !confirmPassword}
          >
            {loading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                Sent...
              </>
            ) : message ? (
              'Sent'
            ) : (
              'Sent'
            )}
          </button>

        </div>


        <div style={{width:'100%', paddingTop:'10px'}} > 
            {validationError && <p className="ErrorMessage">{validationError}</p>}
            {error && <p className="ErrorMessage">{error.msg}</p>}
            {message && <p className="success-message">{message}</p>}
          </div>


      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default ChangeOtpPassword;
