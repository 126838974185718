import React,{ useState , useRef  } from 'react'
import './userhome.css'
import UnauthAppbar from '../../component/AppbarUnAuth/UnauthAppbar'
import Signup from '../../component/Signup/Signup';
import Filmmiking from '../../assets/images/filming.png'
import aiicon from '../../assets/images/Ai.png'
import Business from '../../assets/images/Business.png'
import Fineart from '../../assets/images/FineArt.png'
import Design from '../../assets/images/design.png'
import Programming  from '../../assets/images/programming.png'
import sport from '../../assets/images/sport.png'
import cooking from '../../assets/images/cook.png'
import Wellness from '../../assets/images/wellness.png'
import animals from '../../assets/images/animal.png'
import Hash from '../../assets/images/hash.png'
import Course from '../../component/Course/Course';
import LeftArrow from '../../assets/images/leftarrow.png'
import RightArrow from '../../assets/images/rightarrow.png'
import StartIcon from '../../assets/images/StarReview.png'
import QouteIcon from '../../assets/images/quote.png'
//import ArrowUp from '../../assets/images/Scrollup.png'
//import LndexLogo from '../../assets/images/lndex logo.png'
//import Line from '../../assets/images/Line.svg'
//import FacebookIcon from '../../assets/images/facebookicon.svg'
//import YoutubeIcon from '../../assets/images/youtubeicon.svg'
//import InstgramIcon from '../../assets/images/instgramicon.svg'
import Instructor from '../../component/Instructor/Instructor';
import Article from '../../component/Article/Article';
import Login from '../../component/Login/Login';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext'
//import FooterAR from '../../component/FooterArabic/FooterAr';
//import Footer from '../../component/Footer/Footer'
//import UnauthAppbarAR from '../../component/AppbarUnAuth/UnauthAppbarAR';
//import Signup from '../../component/Signup/Signup'
import HomeUserPanner from '../../assets/images/homepanner.svg'
import UserNavbar from '../../component/UserNavbar/UserNavbar.js';
import GetInstructor from '../../component/Instructor/GetInstructor.js';
import GetCourse from '../../component/Course/GetCourse.js';
import GetEnterpriseCourse from '../../component/Course/GetEnterpriseCourse.js';
import { motion, useAnimation } from "framer-motion";


const UserHome = () => {
    // this from context api to manage language 
    const { language } = useLanguage();
    console.log(language)
      /*
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
      */
      // to show Signup when click button 
   // const [showSignup, setShowSignup] = useState(false);
      // to show Login when click button 
    //const [showLogin, setShowLogin] = useState(false);
    const courseContainerRef = useRef(null);
    const scrollLeft = () => {
        if (courseContainerRef.current) {
            console.log("Scrolling Left");
            console.log(courseContainerRef.current); // Log the element to confirm
            courseContainerRef.current.scrollLeft -= 500; 
        }
    };
   
   const scrollRight = () => {
        if (courseContainerRef.current) {
            console.log("Scrolling Right");
            console.log(courseContainerRef.current); // Log the element to confirm
            courseContainerRef.current.scrollLeft += 500;
        }
    };
    const instructorAtUserPageContainerRef = useRef(null);
    const scrollInstructorLeft = () => {
        if (instructorAtUserPageContainerRef.current) {
            console.log("Before Scroll Left:", instructorAtUserPageContainerRef.current.scrollLeft);
            instructorAtUserPageContainerRef.current.scrollLeft -= 100;
            console.log("After Scroll Left:", instructorAtUserPageContainerRef.current.scrollLeft);
        }
    };
    
    const scrollInstructorRight = () => {
        if (instructorAtUserPageContainerRef.current) {
            console.log("Before Scroll Right:", instructorAtUserPageContainerRef.current.scrollLeft);
            instructorAtUserPageContainerRef.current.scrollLeft += 50;
            console.log("After Scroll Right:", instructorAtUserPageContainerRef.current.scrollLeft);
        }
    };
    // about Article Refrence 
    const ArticleContainerRef = useRef(null);
    const controls = useAnimation();
    const scrollArticleLeft = () => {
        if (ArticleContainerRef.current) {
            controls.start({
                x: ArticleContainerRef.current.scrollLeft - 300,
                transition: { duration: 0.4, ease: "easeInOut" },
            });
        }
    };
    const scrollArticleRight = () => {
        if (ArticleContainerRef.current) {
            controls.start({
                x: ArticleContainerRef.current.scrollLeft + 5,
                transition: { duration: 0.5, ease: "easeInOut" },
            });
        }
    };


  return (
    <div>
        {/* 
         {language === 'en' ?
  <UnauthAppbar showSignup={showSignup} setShowSignup={setShowSignup} showLogin={showLogin} setShowLogin={setShowLogin}/>
            : 
  <UnauthAppbarAR showSignup={showSignup} setShowSignup={setShowSignup} showLogin={showLogin} setShowLogin={setShowLogin}/>
        }
      
        {showSignup ? <div className='Signup_HomeUser'><Signup setShowSignup={setShowSignup}/></div> : ''}
        {showLogin ?  <div className='Signup_HomeUser'><Login setShowLogin={setShowLogin}/> </div> : ''}
        */}
        <div className='HomeUser_container'>
              <UserNavbar/>
            <div className='HomeUser_Sec1'>
                <img src={HomeUserPanner} alt='HomeUserPanner' className='HomeUser_Sec1_Panner'/>
                <div className='HomeUser_Sec1_title'>
                    <h2 className='HomeUser_Sec1_title_h1'>“Your learning journey starts here”</h2>
                    <p className='HomeUser_Sec1_title_p'>Pretium laoreet mi pulvinar hendrerit elementum ultricies. Risus aliquam amet ut ornare in bibendum urna adipiscing.</p>
                </div>
            </div>
        {/* Category hash SEction  */}
        <div className='HomeUser_Sec2'>
           <div className='HomeUser_Sec2_part1'>Explore by category</div>
           <div className='HomeUser_Sec2_part2'>
              <div className='HomeUser_Sec2_part2_items'><img src={Filmmiking} alt='Filmmiking'/>filmmaking & photography</div>
              <div className='HomeUser_Sec2_part2_items'><img src={aiicon} alt='aiicon'/>Artificial intelligence</div>
              <div className='HomeUser_Sec2_part2_items'><img src={Business} alt='Business'/>Business</div>
              <div className='HomeUser_Sec2_part2_items'><img src={Fineart} alt='Fineart'/>fine Arts</div>
              <div className='HomeUser_Sec2_part2_items'><img src={Design} alt='Design'/>Design</div>
              <div className='HomeUser_Sec2_part2_items'><img src={Programming} alt='Programming'/>Programing</div>
              <div className='HomeUser_Sec2_part2_items'><img src={sport} alt='sport'/>Sports</div>
              <div className='HomeUser_Sec2_part2_items'><img src={cooking} alt='cooking'/>Cocking</div>
              <div className='HomeUser_Sec2_part2_items'><img src={Wellness} alt='Wellness'/>Wellness</div>
              <div className='HomeUser_Sec2_part2_items'><img src={animals} alt='animals'/>Animal husbandry</div>
              <div className='HomeUser_Sec2_part2_icon'><img src={Hash} alt='Hash'/></div>
           </div>
        </div>
        {/*  The Course Header  */}
        <div className='HomeUser_Sec3'>
            
            <div className='HomeUser_Sec3_Part2'>
            <motion.img
                    src={LeftArrow}
                    alt="ArrowLeft"
                    onClick={scrollLeft}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    whileHover={{ scale: 1.2 }} // Hover effect
                    whileTap={{ scale: 0.9 }}  // Tap effect
                />
            <div className='HomeUser_Sec3_Part1'>New Courses</div>
            <motion.img
                    src={RightArrow}
                    alt="ArrowRight"
                    onClick={scrollRight}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    whileHover={{ scale: 1.2 }} // Hover effect
                    whileTap={{ scale: 0.9 }}  // Tap effect
                />
            </div>
        </div>
           {/*  The Course Body   */}

        
           <motion.div
                className="HomeUser_Sec4"
                ref={courseContainerRef}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <GetCourse />
               
            </motion.div>
           

            {/* End The Course Body   */}
            <div className='HomeUser_Sec3'>
          
            <div className='HomeUser_Sec3_Part2'>
            <img src={LeftArrow} alt='ArrowLeft'   style={{cursor:'pointer'}}  onClick={scrollInstructorLeft}/>
            <div className='HomeUser_Sec3_Part1'>Top instructors</div>
            <img src={RightArrow} alt='ArrowRight' style={{cursor:'pointer'}}  onClick={scrollInstructorRight} />
            </div>
        </div>
             {/*  The Instructor Body   */}
             <div className='HomeUser_Sec5' ref={instructorAtUserPageContainerRef}>
            {/*  <Link to='/instructor' className='link'> <Instructor /></Link> */}   
              <GetInstructor />
             </div>
               {/* End The Instructor Body   */}
               <div className='HomeUser_Sec3'>
             
               <div className='HomeUser_Sec3_Part2'>
               <img src={LeftArrow} alt='ArrowLeft' onClick={scrollArticleRight} />
               <div className='HomeUser_Sec3_Part1'>New articles</div>
               <img src={RightArrow} alt='ArrowRight' onClick={scrollArticleLeft} />
               </div>
           </div>
             {/*  The Articles Body   */}
           {/* Motion container for the articles */}
            <motion.div
                className="HomeUser_Sec6"
                ref={ArticleContainerRef}
                animate={controls}
            >
                <Article />
            </motion.div>


             <GetEnterpriseCourse />

               {/* End The Articles Body   */}
               {/* strat section 7 about Student review  */}
               <div className='HomeUser_Sec7'>
                   <div className='HomeUser_Sec7_Part1'>Why Students Love Lndex</div>
                   <div className='HomeUser_Sec7_Part2_Container'>
                       <div className='HomeUser_Sec7_Part2_Container_Text'>
                       "I was hesitant to take an offline course at first, but I'm so glad I did! The instructor was knowledgeable and engaging, and the small class size made it easy to get help when I needed it. I learned so much in the course, I would definitely recommend this platform to anyone who is looking for a high-quality offline course."
                       </div>
                       <div className='HomeUser_Sec7_Part2_Container_Star'>
                          <img src={StartIcon} alt='start'/>
                          <img src={StartIcon} alt='start'/>
                          <img src={StartIcon} alt='start'/>
                          <img src={StartIcon} alt='start'/>
                          <img src={StartIcon} alt='start'/>
                       </div>
                      <div className='HomeUser_Sec7_Part2_Container_Student'>
                          <div className='HomeUser_Sec7_Part2_Container_Student_Name'>Muhammed Morsi</div>
                          <div className='HomeUser_Sec7_Part2_Container_Student_Role'>student</div>
                      </div>
                      <div className='QouteIcon'>
                      <img src={QouteIcon} alt='QouteIcon' />
                    </div>
                   </div>
                   {/* Second Review  */}
                   <div className='HomeUser_Sec7_Part2_Container'>
                   <div className='HomeUser_Sec7_Part2_Container_Text'>
                   "I took an offline course on web development from lndex website. The instructor was a certified web developer with over 10 years of experience. He was very knowledgeable and engaging, and he made the material easy to understand. The class size was small, so I got a lot of individual attention. I would definitely recommend this platform to anyone who is interested in learning web development."
                   </div>
                   <div className='HomeUser_Sec7_Part2_Container_Star'>
                      <img src={StartIcon} alt='start'/>
                      <img src={StartIcon} alt='start'/>
                      <img src={StartIcon} alt='start'/>
                      <img src={StartIcon} alt='start'/>
                      <img src={StartIcon} alt='start'/>
                   </div>
                  <div className='HomeUser_Sec7_Part2_Container_Student'>
                      <div className='HomeUser_Sec7_Part2_Container_Student_Name'>Ahmed hossam</div>
                      <div className='HomeUser_Sec7_Part2_Container_Student_Role'>student</div>
                  </div>
                  <div className='QouteIcon'>
                    <img src={QouteIcon} alt='QouteIcon' />
                  </div>
               </div>
               </div>
            
           
        </div> {/* End HomeUser Container */}

            
    </div>
  )
}
export default UserHome
