import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config'; // Adjust the path

// Async thunk to store a new instructor
export const storeInstructor = createAsyncThunk(
  'enterprise/storeInstructor',
  async (formData, { rejectWithValue }) => {
    try {
      const token = Cookies.get('entoken'); // Get token from cookies
      const response = await axios.post(
        `${URL}/enterprise/store-instructor`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token,
          },
        }
      );
      return response.data; // Adjust based on the actual response structure
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.msg || 'Failed to store instructor.'
      );
    }
  }
);

// Update the slice
const enterpriseInstructorsSlice = createSlice({
  name: 'enterpriseInstructors',
  initialState: {
    instructors: [],
    loading: false,
    error: null,
    success: null,
  },
  reducers: {
    clearSuccess: (state) => {
      state.success = null;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(storeInstructor.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(storeInstructor.fulfilled, (state, action) => {
        state.loading = false;
        state.success = 'Instructor added successfully!';
        state.instructors.push(action.payload); // Optional: Add to list if needed
      })
      .addCase(storeInstructor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearSuccess, clearError } = enterpriseInstructorsSlice.actions;
export default enterpriseInstructorsSlice.reducer;
