import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '../../../assets/images/instructors/add.svg';
import CourseDashboard from '../../../component/courseDashboard/courseDashboard';
import { fetchInstructorCourses } from '../../../store/features/instructorCoursesSlice';
import Loading from '../../../component/Loading/Loading'
import Line from '../../../assets/images/Line.svg'
import { motion } from 'framer-motion';
const InstructorCourses = () => {
  const dispatch = useDispatch();
  
  // Ensure to access the correct state slice
  const instructorCourses = useSelector((state) => state.instructorCourses);
  const { courses = [], loading = false, error = null } = instructorCourses;
  console.log('course data test ' , courses)
  useEffect(() => {
    dispatch(fetchInstructorCourses(0));
  }, [dispatch]);
  
  return (
    <div className="container-fluid">
        
      <div className='row mb-3'>
   
        <div className="col-lg-12 d-flex justify-content-end" style={{position:'relative',top:'-50px'}}>
          <Link to='/createcourse' className='link'>
            <button className='InstructorHome_AddCourse_Button'>
              <img src={AddIcon} alt='AddTheIcon' className='me-2' />
              Create New Course
            </button>
          </Link>
        </div>
        
      </div>
       <div className='Line_instractor_Dashboard'>
           <img src={Line} alt='Line' />
       </div>
    
      <div className='Courses_Instructor_Container'>
        {loading && <Loading />}
        {error && <div>Error: {error}</div>}
        {courses.length > 0 ? (
          courses.map((course) => (
            <div key={course.id}>
              <CourseDashboard course={course} />
            </div>
          ))
        ) : (
          <motion.div
          initial={{ opacity: 0, y: -80 }} // حالة البداية
          animate={{ opacity: 1, y: 5 }} // حالة التفعيل
          exit={{ opacity: 0, y: 20 }} // حالة الخروج
          transition={{ duration: 1.5 }} // مدة الحركة
          style={{display:'flex' , justifyContent:'center' , flexDirection:'column', textAlign: 'center', fontSize: '20px', margin: '20px 0' }}
        >
          <p>No courses are currently available.</p>
          <p>If you've recently created courses, <span className='Instructor_Activation'>please wait for activation.</span></p>
          <p>Feel free to check back later!</p>
        </motion.div>

        )}
      </div>
 
    </div>
  );
};

export default InstructorCourses;
