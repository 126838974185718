// store/enterpriseFeature/instructorEnterpriseByidSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config'; // Adjust the path to your config file
import Cookies from 'js-cookie';


// Async thunk for fetching instructor by ID
export const fetchInstructorById = createAsyncThunk(
  'instructorEnterprise/fetchById',
  async (id, { rejectWithValue }) => {
    try {
         const token = Cookies.get('entoken'); // Get token from cookies
      const response = await axios.get(`${URL}/enterprise/instructor/${id}`, {
        headers: {
          token, // Assuming you are sending a token for authentication
        },
      });
      return response.data; // Returning the data from the API response
    } catch (error) {
      return rejectWithValue(error.response.data || error.message); // Returning error message if the request fails
    }
  }
);

const instructorEnterpriseByidSlice = createSlice({
  name: 'instructorEnterpriseByid',
  initialState: {
    instructor: null,
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstructorById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchInstructorById.fulfilled, (state, action) => {
        state.loading = false;
        state.instructor = action.payload; // Store the fetched instructor data
      })
      .addCase(fetchInstructorById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message
      });
  },
});

export default instructorEnterpriseByidSlice.reducer;
