import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config'; // Import URL from config
import Cookies from 'js-cookie';

// Fetch user's cart data
export const fetchUserShowCart = createAsyncThunk(
  'cart/fetchUserShowCart',
  async (_, { rejectWithValue }) => {
    try {
      const token = Cookies.get('authToken'); // Retrieve the token from cookies
      const config = {
        headers: {
          token: token, // Include the token in the headers
        },
      };

      const response = await axios.get(`${URL}/user-courses-citems`, config); // Fetch cart data from the API
      return response.data.data.cart; // Extract the items array from the response
    } catch (err) {
      return rejectWithValue(err.response?.data || 'Error fetching cart data'); // Handle errors
    }
  }
);

const userShowCartSlice = createSlice({
  name: 'userShowCart',
  initialState: {
    courses: [], // Initial state for the courses array
    status: 'idle', // Initial status
    error: null, // Error state
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserShowCart.pending, (state) => {
        state.status = 'loading'; // When the request is in progress
        state.error = null;
      })
      .addCase(fetchUserShowCart.fulfilled, (state, action) => {
        console.log("Fetched courses:", action.payload); // Log the fetched data
        state.status = 'succeeded'; // When the request is successful
        state.courses = action.payload || []; // Update the courses state
      })
      .addCase(fetchUserShowCart.rejected, (state, action) => {
        state.status = 'failed'; // When the request fails
        state.error = action.payload || 'Failed to load cart data'; // Store the error message
      });
  },
});

export default userShowCartSlice.reducer;
