import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Clock from '../../assets/images/Course/circle-clock1.png';
import Chair from '../../assets/images/Course/Chair.png';
import { URL } from '../../config'; // Ensure this is defined correctly
import './search.css';
import Line from '../../assets/images/Course/Line.png';
import Start from '../../assets/images/Course/Star.png';
import SavedCompact from '../../assets/images/Course/SavedCompact.svg';
import Saved from '../../assets/images/Course/CourseSaved.png';
import AddToCart from '../../assets/images/coursecard/shopping-cart-details.svg';
import { addCourseToCart } from '../../store/userFeature/addCourseToCartSlice';
import { useDispatch } from 'react-redux';

// Utility function for fetching courses
const fetchCourses = async () => {
  try {
    // Fetch all regular courses from the API
    const response = await axios.post(`${URL}/courses`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Error fetching regular courses';
  }
};

// Utility function for fetching enterprise courses
const fetchEnterpriseCourses = async () => {
  try {
    // Fetch all enterprise courses from the API
    const response = await axios.get(`${URL}/enterprise/all-courses`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Error fetching enterprise courses';
  }
};

const Search = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState(null); // State for success/error message
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get('query'); // Get the search query from the URL
  const [allCourses, setAllCourses] = useState([]); // Store all fetched courses
  const [filteredCourses, setFilteredCourses] = useState([]); // Store filtered courses
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state
  const [query, setQuery] = useState(searchQuery || ''); // Local search state

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        // Fetch regular and enterprise courses from the API
        const [regularCoursesData, enterpriseCoursesData] = await Promise.all([
          fetchCourses(),
          fetchEnterpriseCourses()
        ]);

        const allCourses = [
          ...regularCoursesData.data.courses,  // Regular courses
          ...enterpriseCoursesData.data.courses // Enterprise courses
        ];

        setAllCourses(allCourses); // Combine both courses into one array

        // Filter courses based on searchQuery
        setFilteredCourses(
          allCourses.filter((course) =>
            course.title.toLowerCase().includes((searchQuery || '').toLowerCase())
          )
        );
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [searchQuery]); // Refetch whenever the searchQuery changes

  // Handle input change and update URL query
  const handleSearchChange = (event) => {
    const query = event.target.value;
    setQuery(query);
    navigate(`?query=${query}`); // Update URL with search query

    // Filter courses locally
    setFilteredCourses(
      allCourses.filter((course) =>
        course.title.toLowerCase().includes(query.toLowerCase())
      )
    );
  };
     // handle course by check the course from Enterprise or not 
  const handleCourseClick = (course) => {
    if (course?.instructor?.enterprise) {
      navigate(`/enterprise/coursedetails/${course.id}`);
    } else {
      navigate(`/coursedetails/${course.id}`);
    }
  };


  const handleAddToCart = async (e, course) => {
    e.stopPropagation(); // Prevent parent click event
  
    try {
      const response = await dispatch(
        addCourseToCart({
          courseId: course.id,
          isEnterprise: course?.instructor?.enterprise || false,
        })
      ).unwrap();
      setMessage({ type: 'success', text: response.msg });
    } catch (error) {
      setMessage({
        type: 'error',
        text: error.message || 'Failed to add course to cart.',
      });
    }
  
    // Auto-hide the message after 3 seconds
    setTimeout(() => setMessage(null), 3000);
  };
  

  // Render sections for loading, error, and results
  const renderContent = () => {
    if (loading) return <p>Loading...</p>;
    if (error) return <p style={{ color: 'red' }}>Error: {error}</p>;
    if (filteredCourses.length === 0) {
      if (searchQuery) {
        return <p>No results found for your search term: "{searchQuery}"</p>;
      } else {
        return <p>No courses found.</p>;
      }
    }



    return (
      <ul className="search_course_container">
        {filteredCourses.map((course, index) => (
          <div
           key={index}
           onClick={() => handleCourseClick(course)}
         
          >
            <div className="search_course_card">
              <img src={course?.image} className="search_course_card_img" />
              <div className="search_course_card_Content">
                <div>{course.title}</div>
                <div>{course?.instructor?.enterprise ? 'Enterprise' : ''}</div>
                <div className='Course_Sec4'>
                  <img src={Clock} alt='Clock' /> {course.course_hours} Hours ({course.session_count} sessions)
                </div>

                <div className='Course_Sec5'>
                 <img src={Chair} alt='Chair' />{course.seats_count} seats
                 <img src={Line} alt='Line' />
                 <img src={Start} alt='Start' /> {course.rate ? course.rate : '95%'}(234)
               </div>

               <div style={{ paddingTop: '8px', color: 'blue' }} className='Course_Sec6'>
                    {course.category.title}
               </div>
               <div className='Course_Sec6'>{course.instructor.name}</div>

               <div className="Course_Sec7_Home_guest">
                {/* 
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {course?.is_saved === 1  ? (
                 <img src={SavedCompact} alt="SavedCompact" />
                 ) : (
                 <img src={Saved} alt="Save" />
                )}

              </div>
              */}
              <div
              onClick={(e) => handleAddToCart(e, course)}
              >
                <img src={AddToCart} alt="addtocart_icon" />
               
              </div>
             
            </div>



              </div>
            </div>
          </div>
        ))}
      </ul>
    );
  };

  return (
    <div>
      <h1>{searchQuery ? `Search Results` : `All Courses`}</h1>
      <p>
        {searchQuery
          ? `Results for: "${searchQuery}"`
          : 'Here are all the courses available.'}
      </p>

      <input
        type="text"
        placeholder="Search by title"
        value={query}
        onChange={handleSearchChange}
      />

      {renderContent()}
      {message && (
        <div
          className={`success-message ${message.type === 'success' ? 'show' : ''} ${
            message.type === 'error' ? 'error-message' : ''
          }`}
        >
          {message.text}
        </div>
      )}
    
  
    </div>
  );
};

export default Search;
