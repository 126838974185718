import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CourseImg from '../../assets/images/Course/Courseimage.png';
import Clock from '../../assets/images/Course/circle-clock1.png';
import Chair from '../../assets/images/Course/Chair.png';
import Line from '../../assets/images/Course/Line.png';
import Start from '../../assets/images/Course/Star.png';
import DeleteCourse from '../../assets/images/DeleteCourse.svg';
import UpdateCourseIcon from '../../assets/images/editcourse.svg';
import Loading from '../../component/Loading/Loading';
import { fetchEnterpriseCourses } from '../../store/enterpriseFeature/enterpriseCoursesSlice';
import { unarchiveEnterpriseCourse } from '../../store/enterpriseFeature/unarchiveEnterpriseCourseSlice';
import { motion } from 'framer-motion';

const ArchiveEnterprise = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Selector for fetching courses
  const { courses = [], loading, error } = useSelector((state) => state.enterpriseCourses);

  // Selector for unarchiving state
  const { loading: loadingUnarchive, successMessage, error: errorUnarchive } = useSelector(
    (state) => state.unarchiveEnterpriseCourse
  );

      // Fetch courses on component mount
   useEffect(() => {
     dispatch(fetchEnterpriseCourses(1)); // Fetch non-archived courses
   }, [dispatch]);
  

  // Utility function to truncate text
  const truncate = (text, limit) => {
    if (text.length > limit) {
      return text.slice(0, limit) + '...';
    }
    return text;
  };

  // Handle navigation to update course
  const handleUpdateClick = (course) => {
    navigate(`/update-course/${course.id}`, { state: { course } });
  };

  // Handle unarchive click
  const handleUnarchiveClick = async (courseId) => {
    try {
      const resultAction = await dispatch(unarchiveEnterpriseCourse(courseId));
      if (unarchiveEnterpriseCourse.fulfilled.match(resultAction)) {
        console.log("Course unarchived successfully:", resultAction.payload);
         // Reload the page
       window.location.reload();
      } else {
        console.error("Failed to unarchive course:", resultAction.payload);
      }
    } catch (error) {
      console.error("Error unarchiving course:", error);
    }
  };

  if (loading || loadingUnarchive) return <Loading />;
  if (error) return <p>Error: {error}</p>;
  if (errorUnarchive) return <p>Error Unarchiving: {errorUnarchive}</p>;

  return (
    <div className="ArchiveCoursesList">
      {courses.length > 0 ? (
        courses.map((course) => (
          <div key={course.id} className="Course_Container">
            <div className="Course_Sec1">
              <img
                style={{ width: '310px', height: '186px', borderRadius: '8px 8px 0px 0px' }}
                src={course.image || CourseImg}
                alt="Course"
              />
            </div>
            <div className="Course_Sec2">Start {course.start_date || 'N/A'}</div>
            <div className="Course_Sec3_at_dashboard">{course.title}</div>
            <div className="Course_Sec4">
              <img src={Clock} alt="Clock" /> {course.course_hours} hours &nbsp; (
              {course.session_count} sessions)
            </div>
            <div className="Course_Sec5">
              <img src={Chair} alt="Chair" /> {course.seats_count} seats left
              <img src={Line} alt="Line" />
              <img src={Start} alt="Start" /> {course.rate || 'N/A'} ({course.session_count || 0})
            </div>
            <div className="Course_Sec6">{truncate(course.description, 50)}</div>
            <div className="Course_Sec7">
              <button
                className="Course_Dashboard_Archive"
                style={{marginTop:'10px'}}
                onClick={() => handleUnarchiveClick(course.id)}
                disabled={loadingUnarchive}
              >
                {loadingUnarchive ? 'Unarchiving...' : 'UnArchive'}
              </button>
              {/* 
              <div className="course_icon_modify">
                <img
                  src={UpdateCourseIcon}
                  alt="Update Course"
                  onClick={() => handleUpdateClick(course)}
                />
                <img src={DeleteCourse} alt="Delete Course" />
              </div>
              */}
            </div>
          </div>
        ))
      ) : (
        <motion.div
        initial={{ opacity: 0, y: -80 }} // حالة البداية
        animate={{ opacity: 1, y: 5 }} // حالة التفعيل
        exit={{ opacity: 0, y: 20 }} // حالة الخروج
        transition={{ duration: 1.5 }} // مدة الحركة
        style={{display:'flex' , justifyContent:'center' , flexDirection:'column', textAlign: 'center', fontSize: '20px', margin: '20px 0' }}
      >
        <p>No archived courses available.</p>
        </motion.div>
      )}
      {successMessage && <p>{successMessage}</p>}
    </div>
  );
};

export default ArchiveEnterprise;
