import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'; 
import Spinner from 'react-bootstrap/Spinner';
//import { instructorLogin } from '../../features/instructorLoginSlice'; // استيراد thunk لتسجيل الدخول
import { instructorLogin } from '../../store/features/instructorLoginSlice'; // Correct import
import PasswordInput from '../../component/PasswordInput/PasswordInput';

const InstructorLogin = () => {
  const [email, setemail] = useState('');
  const [password, setPassword] = useState('');
 
  const dispatch = useDispatch(); // استخدام dispatch لإرسال إجراءات Redux
  const navigate = useNavigate();

  const { loading, error } = useSelector((state) => state.instructorLogin); // استدعاء الحالة من Redux

  const handleemail = (e) => setemail(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);

  const handleInstructorSubmit = () => {
    dispatch(instructorLogin({ email, password }))
      .then((action) => {
        if (instructorLogin.fulfilled.match(action)) {
          navigate('/Instructor-Home');
        } else {
          console.error('Login failed:', action.payload);
        }
      });
  };

    // Handle key press event
    const handleKeyPress = (e) => {
      console.log(`Key pressed: ${e.key}`); // Debugging
      if (e.key === 'Enter') {
        handleInstructorSubmit();
      }
    };
  return (
    <div className='Login_body_input_container'>
      <div className='Signup_body_Form_Sec4'>
        <div>Email</div>
        <div className='Signup_body_input'>
          <input
            className='Sign_input'
            type="text"
            placeholder='example@example.com'
            value={email}
            onChange={handleemail}
            onKeyDown={handleKeyPress}
          />
        </div>
      </div>

      <div className='Signup_body_Form_Sec4'>
        <div>Password</div>
        <div className='Signup_body_input'  onKeyDown={handleKeyPress}>
          <PasswordInput
            className='Sign_input'
            type="password"
            placeholder='Password'
            value={password}
            onChange={handlePassword}
            onKeyDown={handleKeyPress}
          />
        </div>
      </div>

      <div className='Error_message'>
        {error && (
          <div style={{ color: 'red', marginTop: '10px'}}>
            <p>{error.msg}</p>
          </div>
        )}
      </div>

      <div className='Signup_body_Form_Sec5' style={{display:'flex',flexDirection:'row-reverse'}}>
        {loading ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <button className='Signup_body_Form_Sec5_button' onClick={handleInstructorSubmit}>
            Login
          </button>
        )}

        <div style={{ display: 'flex', flexDirection: "column-reverse", alignItems: 'flex-start', gap: '10px' }}>
        
          <Link to='/instructor-forgot-password'>
            <div style={{ cursor: 'pointer' }}>Forget Password ?</div>
          </Link>
          <div>Don’t have an account ? <Link to='/Signup' className='link'><span>Sign Up</span></Link></div>
        </div>
      </div>
    </div>
  );
};

export default InstructorLogin;
