import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './Notification.css'; // Add your CSS file for custom styles
import { LineUtil } from 'leaflet';
import { Link } from 'react-router-dom';
import popupalert from '../../assets/images/popupAlert.svg'



const NewUserNotification = ({ showNotification, onClose }) => {
  return (
    showNotification && (
      <>
      <div class="overlay"></div>
      <motion.div
        className="notification-container"
        initial={{ opacity: 0, x: '100%' }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: '100%' }}
        transition={{ duration: 0.5 }}
      >
        <div className="notification-content">
          <div className='popupalert'>
              <img src={popupalert} alt='popupalert'/>
              <div>profile update !</div>
          </div>
          <span className="notification-message">
          profile information may be out of date! For the most accurate and up-to-date information, please update your profile details. 
          </span>
          
            <div className='notification_buttons'>
            <button className='notification_buttons_NotNOW' onClick={onClose}>not now</button>
               <Link className='link' to='/enterprise/Information-form'>
                 <button className='notification_buttons_Update'>update profile</button> 
               </Link>  
            </div>
        
         
        </div>
      </motion.div>

      </>
    )
  );
};

export default NewUserNotification;
