import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config'; // Import the URL from config

// Define the initial state for the slice
const initialState = {
  loading: false,
  error: null,
  success: false,
};

// Create the async thunk to update the instructor enterprise
export const updateInstructorEnterprise = createAsyncThunk(
  'instructorEnterprise/update',
  async (data, { rejectWithValue }) => {
    try {
      const token = Cookies.get('entoken');
      const response = await axios.post(
        `${URL}/enterprise/update-instructor`, // Use the URL from config
        data,
        {
          headers: {
            token,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create the slice
const updateInstructorEnterpriseSlice = createSlice({
  name: 'updateInstructorEnterprise',
  initialState,
  reducers: {
    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateInstructorEnterprise.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateInstructorEnterprise.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(updateInstructorEnterprise.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});

// Export actions and reducer
export const { resetState } = updateInstructorEnterpriseSlice.actions;
export default updateInstructorEnterpriseSlice.reducer;
