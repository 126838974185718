import React, { useEffect, useState } from 'react';
import './Course.css';
import Clock from '../../assets/images/Course/circle-clock1.png';
import Chair from '../../assets/images/Course/Chair.png';
import Line from '../../assets/images/Course/Line.png';
import Start from '../../assets/images/Course/Star.png';
import SavedCompact from '../../assets/images/Course/SavedCompact.svg';
import Saved from '../../assets/images/Course/CourseSaved.png';
import AddToCart from '../../assets/images/coursecard/shopping-cart-details.svg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllCourses } from '../../store/userFeature/GetUserCourseSlice';
import { fetchUserShowCart } from '../../store/userFeature/showUserCartSlice';
import Loading from '../Loading/Loading';
import { useNavigate } from 'react-router-dom';
import { addCourseToCart } from '../../store/userFeature/addCourseToCartSlice';
import { saveCourse } from '../../store/userFeature/userSavedCourseSlice';
import { Link } from 'react-router-dom';
import { resetCartState } from '../../store/userFeature/addCourseToCartSlice';
import PromoYoutube from '../../assets/images/Course/promo.svg'
import Cookies from 'js-cookie';

const GetCourse = () => {
  const token = Cookies.get('authToken');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [localSavedCourses, setLocalSavedCourses] = useState({}); // Local state for saved courses

  const { courses, status, error } = useSelector((state) => state.allCourses);
  const { success, adding, courseData } = useSelector((state) => state.userAddToCart);
  const { saved, loading: saving } = useSelector((state) => state.userSavedCourse);

  useEffect(() => {
    dispatch(fetchAllCourses());
  }, [dispatch]);

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleCourseClick = (id) => {
    navigate(`/coursedetails/${id}`);
  };

  const handleAddToCartClick = (courseId) => {
    if (!token) {
      setPopupMessage('Please log in to save courses to your cart.');
      setShowPopup(true);
      return;
    }
    dispatch(addCourseToCart({courseId , isEnterprise : false }));
     setTimeout(() => {
        dispatch(fetchUserShowCart());
      }, 500); // مهلة قصيرة للتأكد من اكتمال إضافة العنصر في الـ API
  };

  const handleSaveCourseClick = (courseId) => {
    if (!token) {
      setPopupMessage('Please log in to save courses.');
      setShowPopup(true);
      return;
    }

    // Optimistically update the UI
    setLocalSavedCourses((prev) => ({ ...prev, [courseId]: true }));

    // Dispatch the save course action
    dispatch(saveCourse({ courseId, is_enterprise: false })).then((action) => {
      if (action.type.endsWith('/rejected')) {
        // Rollback UI update if the action fails
        setLocalSavedCourses((prev) => ({ ...prev, [courseId]: false }));
        setPopupMessage('Failed to save the course.');
        setShowPopup(true);
      }
    });
  };

  useEffect(() => {
    if (success || error) {
      setPopupMessage(success ? 'Course added to cart successfully!' : 'Failed to add course to cart.');
      setShowPopup(true);

      const timer = setTimeout(() => {
        setShowPopup(false);
        dispatch(resetCartState());
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [success, error, dispatch]);

  if (status === 'loading') return <Loading />;
  if (status === 'failed') return <p>Error: {error}</p>;

  return (
    <>
      {courses.map((course) => {
        const isSaved = localSavedCourses[course.id] || course.is_saved;

        return (
          <div
            className='Course_Container'
            key={course.id}
            onClick={() => handleCourseClick(course.id)}
          >
            <div className='Course_Sec1'>
              <img className='Course_Sec1_image' src={course.image} alt='CourseImg' />
            </div>
            <div className='Course_Sec2'>

            <div>start {course.times[0]?.date}</div> <div>watch promo <img src={PromoYoutube} alt='PromoYoutube'></img></div>
            </div>
            <div className='Course_Sec3'>{course.title}</div>
            <div className='Course_Sec4'>
              <img src={Clock} alt='Clock' /> {course.course_hours} &nbsp; ({course.session_count} sessions)
            </div>
            <div className='Course_Sec5'>
              <img src={Chair} alt='Chair' /> {course.seats_count} seats left
              <img src={Line} alt='Line' />
              <img src={Start} alt='Start' /> {course.rate ? course.rate : '95%'}(234)
            </div>
            <div style={{ paddingTop: '8px', color: 'blue' }} className='Course_Sec6'>
              {course.category.title}
            </div>
            <div className='Course_Sec6'>{course.instructor.name}</div>
            <div className='Course_Sec7_Home_guest'>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleSaveCourseClick(course.id);
                }}
              >
                <img src={isSaved ? SavedCompact : Saved} alt="Save" />
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddToCartClick(course.id);
                }}
              >
                <img src={AddToCart} alt="addtocart_icon" />
              </div>
            </div>
          </div>
        );
      })}
        
        {/*
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <div>
              <span className="close" onClick={closePopup}>&times;</span>
            </div>
            <div><p className='Popup_Message'>{popupMessage}</p></div>
            {!token && (
              <div className='popup_button_container'>
                <Link className="link" to="/Login">
                  <div>
                    <button className="popup_button_Login">Login</button>
                  </div>
                </Link>
                <Link className="link" to="/Signup">
                  <div>
                    <button className="popup_button_Signup">Sign Up</button>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
 */}

    </>
  );
};

export default GetCourse;
