import React, { useState, useEffect } from "react";
import addImage from "../../assets/images/addImage.svg";
import "./EnterpriseGallery.css";
import { useSelector, useDispatch } from "react-redux";
import { fetchEnterpriseProfile } from "../../store/enterpriseFeature/profileEnterpriseSlice";
import { updateEnterpriseProfile } from "../../store/enterpriseFeature/updateEnterpriseProfileSlice";
import DeleteIcon from "../../assets/images/DeleteCourse.svg";
import RightArrow from "../../assets/images/R_arrow_Slide.svg";
import LeftArrow from "../../assets/images/L_arrow_Slide.svg";
import Cookies from "js-cookie";
import axios from "axios";
import { URL as ConfigURL } from "../../config";

const EnterpriseGallery = () => {
  const [mainImage, setMainImage] = useState(null);
  const [images, setImages] = useState([]);
  const [newImage, setNewImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.enterpriseProfile);
  const EnterpricedataImage = profile?.data?.enterprise?.images;

  useEffect(() => {
    if (!profile?.data) {
      dispatch(fetchEnterpriseProfile());
    }
  }, [dispatch, profile]);

  useEffect(() => {
    if (EnterpricedataImage?.length > 0 && images.length === 0) {
      const fetchedImages = EnterpricedataImage.map((item) => ({
        image: item.image,  // رابط الصورة
        id: item.id         // الـ id الخاص بالصورة
      }));
      setImages(fetchedImages);

      if (!mainImage) {
        setMainImage(fetchedImages[0]);
      }
    }
  }, [EnterpricedataImage, images.length, mainImage]);

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => ({
      image: URL.createObjectURL(file),
      id: Math.random().toString(36).substring(7),
    }));
  
    console.log("New images:", newImages);  // تحقق من الروابط المؤقتة هنا
  
    setImages((prevImages) => [...prevImages, ...newImages]);
  
    if (!mainImage) {
      setMainImage(newImages[0]);
    }
  
    setNewImage(files[0]);
  };

  const handleSlideClick = (image) => {
    setMainImage(image);
  };

  const slideLeft = () => {
    const currentIndex = images.findIndex((item) => item.image === mainImage.image);
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setMainImage(images[prevIndex]);
  };
  
  const slideRight = () => {
    const currentIndex = images.findIndex((item) => item.image === mainImage.image);
    const nextIndex = (currentIndex + 1) % images.length;
    setMainImage(images[nextIndex]);
  };

  const handleSubmitNewImage = async () => {
    if (newImage) {
      setLoading(true);
      setError(null);
      try {
        const formData = new FormData();
        formData.append("images_enterprise[]", newImage);
        await dispatch(updateEnterpriseProfile(formData));
        dispatch(fetchEnterpriseProfile());
        setNewImage(null);
      } catch (err) {
        setError("Failed to upload the image. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDeleteImage = async (imageId) => {
    setLoading(true);
    setError(null);
    try {
      const token = Cookies.get("entoken");
  
      if (!token) {
        setError("Authentication token is missing. Please log in again.");
        return;
      }
  
      const response = await axios.post(
        `${ConfigURL}/enterprise/delete-image`,
        { image_id: imageId },
        {
          headers: {
            token: token,
          },
        }
      );
  
      console.log("Image deleted successfully:", response.data.message);
  
      // Display success message
      setSuccessMessage("Image deleted successfully!");
  
      // Remove the deleted image from the list
      setImages((prevImages) =>
        prevImages.filter((image) => image.id !== imageId)
      );
      
      // Optionally, clear the success message after 3 seconds
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
  
    } catch (error) {
      setError("Failed to delete the image. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="EnterpriseGallery_LinkAlbum_Container">
      {mainImage && (
        <div className="EnterpriseGallery_MainImage_Container">
          <img
            src={mainImage.image}
            alt="Main"
            className="EnterpriseGallery_MainImage"
          />
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
          paddingRight: "40px",
          paddingLeft: "40px",
        }}
      >
        <div
          className="EnterpriseGallery_LinkAlbum_images"
          onClick={() => document.getElementById("imageUploadInput").click()}
        >
          <img src={addImage} alt="addImage" />
          Images
        </div>
        <input
          id="imageUploadInput"
          type="file"
          multiple
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleImageUpload}
        />
      </div>

      {images.length > 1 && (
        <div className="EnterpriseGallery_Slider">
          <button className="SliderArrow LeftArrow" onClick={slideLeft}>
            <img src={LeftArrow} alt="LeftArrow" />
          </button>
          <div className="SliderImages">
            {images.map((image, index) => (
              <div key={image.id || index} className="SliderImageContainer">
                <img
                  src={image.image}
                  alt={`Slide ${image.id || index}`}
                  className={`SliderImage ${
                    image === mainImage ? "Selected" : ""
                  }`}
                  onClick={() => handleSlideClick(image)}
                />
                <button
                  className="DeleteButton"
                  onClick={() => handleDeleteImage(image.id)}
                >
                  <img src={DeleteIcon} alt="DeleteIcon" />
                  Delete
                </button>
              </div>
            ))}
          </div>
          <button className="SliderArrow RightArrow" onClick={slideRight}>
            <img src={RightArrow} alt="RightArrow" />
          </button>
        </div>
      )}

      {newImage && (
        <button
          onClick={handleSubmitNewImage}
          className="EnterpriseGallery_Save"
          disabled={loading}
        >
          {loading ? "Saving..." : "Save New Image"}
        </button>
      )}

      {error && <p className="ErrorMessage">{error}</p>}
       {/* Display success message */}
      {successMessage && <p className="SuccessMessage">{successMessage}</p>}
    </div>
  );
};

export default EnterpriseGallery;
