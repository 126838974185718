import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInstructorCourses } from '../../../../store/features/instructorCoursesSlice';
import { unarchiveCourse } from '../../../../store/features/CourseUnArchiveSlice';
import CourseImg from '../../../../assets/images/Course/Courseimage.png';
import Clock from '../../../../assets/images/Course/circle-clock1.png';
import Chair from '../../../../assets/images/Course/Chair.png';
import Line from '../../../../assets/images/Course/Line.png';
import Start from '../../../../assets/images/Course/Star.png';
import DeleteCourse from '../../../../assets/images/DeleteCourse.svg';
import UpdateCourseIcon from '../../../../assets/images/editcourse.svg';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../component/Loading/Loading'

const ArchiveCourse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const instructorCourses = useSelector((state) => state.instructorCourses);
  const { courses = [], loading = false , error = null } = instructorCourses;

  const courseUnArchive = useSelector((state) => state.courseUnArchive);
  const { loading: loadingUnarchive, error: errorUnarchive, message } = courseUnArchive;


  useEffect(() => {
    dispatch(fetchInstructorCourses(1)); // Fetch courses for instructor with ID 1
  }, [dispatch]);

  // Utility function to truncate text
  const truncate = (text, limit) => {
    if (text.length > limit) {
      return text.slice(0, limit) + '...';
    }
    return text;
  };

  // Handle navigation to update course
  const handleUpdateClick = (course) => {
    navigate(`/update-course/${course.id}`, { state: { course } });
  };

  if (loading) return <Loading />;

  if (error) return <p>Error: {error}</p>;


    // Handle unarchive click
    const handleUnarchiveClick = async (courseId) => {
      try {
        // Dispatch the unarchiveCourse action and wait for the result
        const resultAction = await dispatch(unarchiveCourse(courseId));
    
        // Check if the action was fulfilled
        if (unarchiveCourse.fulfilled.match(resultAction)) {
          // Reload the page after successful unarchiving
          window.location.reload(); // Reload the page
        } else {
          console.error("Failed to unarchive course:", resultAction.payload);
        }
      } catch (error) {
        console.error("Error unarchiving course:", error);
      }
    };
    
    // Loading and error handling

  if (loading || loadingUnarchive) return <Loading />;
  if (error) return <p>Error: {error}</p>;
  if (errorUnarchive) return <p>Error Unarchiving: {errorUnarchive}</p>;

  return (
    <div className="ArchiveCoursesList">
      {courses.length > 0 ? (
        courses.map((course) => (
          <div key={course.id} className="Course_Container">
            <div className="Course_Sec1">
              <img style={{ width: '310px', height: '160px' , borderRadius:'8px 8px 0px 0px' }} src={course.image || CourseImg} alt="Course" />
            </div>
            <div className="Course_Sec2">Start {course.start_date || 'N/A'}</div>
            <div className="Course_Sec3_at_dashboard">{course.title}</div>
            <div className="Course_Sec4">
              <img src={Clock} alt="Clock" /> {course.course_hours} hours &nbsp; ({course.session_count} sessions)
            </div>
            <div className="Course_Sec5">
              <img src={Chair} alt="Chair" /> {course.seats_count} seats left
              <img src={Line} alt="Line" />
              <img src={Start} alt="Start" /> {course.rate || 'N/A'} ({course.session_count || 0})
            </div>
            <div className="Course_Sec6">{truncate(course.description, 50)}</div>
            <div className="Course_Sec7">
            <button className="Course_Dashboard_Archive" onClick={() => handleUnarchiveClick(course.id)}>
                {loadingUnarchive ? 'Unarchiving...' : 'UnArchive'}
              </button>
              <div className="course_icon_modify">
                <img
                  src={UpdateCourseIcon}
                  alt="Update Course"
                  onClick={() => handleUpdateClick(course)}
                />
                <img src={DeleteCourse} alt="Delete Course" />
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No archived courses available.</p>
      )}
         {message && <p>{message}</p>} {/* عرض الرسالة إذا كانت موجودة */}
    </div>
  );
};

export default ArchiveCourse;
