import React , {useState}  from 'react'
import ArrowLeftGuide from '../../assets/images/ArrowLeft.svg'
import Line from '../../assets/images/Line.svg'
import './Blog.css'
//import Signup from '../../component/Signup/Signup'
//import Login from '../../component/Login/Login'
import FeaturedArticles from '../../component/FeaturedArticles/FeaturedArticles'
import MostRecommended from '../../component/RecommendedArticle/MostRecommended'
import NewArticles from '../../component/NewArticle/NewArticle'
import { Link } from 'react-router-dom'
import SignupBlog from '../../component/SignUp_Blog/SignupBlog'
import Cookies from 'js-cookie';
const Blog = () => {

     //   Get Token From Cookies
     const token = Cookies.get('authToken');//authToken
      /*
    const handleLogin =()=>{
        setShowLogin(true);
        console.log('test')
      } 
      */
      /* swatch */
      const [activeSection, setActiveSection] = useState('featured');

  const renderSection = () => {
    switch (activeSection) {
      case 'featured':
        return <FeaturedArticles />;
      case 'recommended':
        return <MostRecommended />;
      case 'new':
        return <NewArticles />;
      default:
        return null;
    }
  };
  return (
    <div>
         <div className='Blog_Container'>
            {/* section 1 */}
            <div className='Blog_Sec1'>
            <Link to='/' className='link'><div>home</div> </Link>
                <div><img src={ArrowLeftGuide} alt='arrow'/></div> 
                <div>Blog</div>
            </div>
            <div className='Wrap_Sec1_sec2'>
            {/* section 2 */}
            <div className='Blog_Sec2'>
                 <div className='Blog_Sec2_P1'>Learn something new every day.</div>
                 <div className='Blog_Sec2_P2'>
                  Our blog is a one-stop shop for all things learning and knowledge. We cover a wide range of topics, from personal development to science and technology. Whether you're looking to learn a new skill, expand your knowledge, or simply stay curious, we have something for everyone.
                  </div>
            </div>
             {/* section 3 */}
             {token ? '' :  '' } {/*<SignupBlog/> */}     
           {/* {showLogin ?  <div className='Signup_home'><Login setShowLogin={setShowLogin}/> </div> : ''}  */}  
             </div> {/* End Wrap Section  */}
             {/* Section 4 */}
             <div className='Blog_Sec4'>
             <div
               className={activeSection === 'featured' ? 'active' : ''}
               onClick={() => setActiveSection('featured')}
             >
               Featured articles
             </div>
             <div
               className={activeSection === 'recommended' ? 'active' : ''}
               onClick={() => setActiveSection('recommended')}
             >
               Most recommended
             </div>
             <div
               className={activeSection === 'new' ? 'active' : ''}
               onClick={() => setActiveSection('new')}
             >
               New articles
             </div>
           </div>
             <div className='Line'><img className='Blog_Line' src={Line} alt='Line'/></div>
             <div>
                {renderSection()}
              </div>
             
         </div> {/* End of Blog Container  */}
        
    </div>
  )
}
export default Blog
   