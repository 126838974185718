import React, { useEffect } from "react";
import addImage from "../../assets/images/addImage.svg";
import AddIcon from '../../assets/images/instructors/add.svg';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";  // Import Redux hooks
import { fetchEnterpriseInstructors } from "../../store/enterpriseFeature/instructorEnterpriseSlice";  // Import the action
import { useNavigate } from "react-router-dom";


const EnterpriseInstructorComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Access the Redux store to get instructors data
  const { instructors, loading, error } = useSelector((state) => state.enterpriseInstructors);

  // Fetch instructors on component mount
  useEffect(() => {
    dispatch(fetchEnterpriseInstructors());
  }, [dispatch]);

  const handleEdit = (id) => {
    navigate(`/enterprise/update-instructor/${id}`); // Navigate to the edit route with the instructor ID
  };

  return (
    <div className="EnterpriseInstructor_Container">
      <div className="EnterpriseInstructor_LinkAlbum">
        <div className="col-lg-12 d-flex justify-content-end" style={{position:'relative', top:'-50px'}}>
          <Link to='/enterprise/create-instructor' className='link'>
            <button className='InstructorHome_AddInstructor_Button'>
              <img src={AddIcon} alt='AddTheIcon' className='me-1' />
              Add Instructor
            </button>
          </Link>
        </div>
      </div>

      {/* Display loading or error messages */}
      {loading && <div>Loading...</div>}
      {error && <div>{error}</div>}

      {/* Display Instructor Cards */}
      <div className="EnterpriseInstructor_Card_Container">
        {instructors.length === 0 ? (
          <div>No instructors found.</div>
        ) : (
          instructors.map((instructor) => (
            <div className="EnterpriseInstructor_Card" key={instructor.id}>
              <div className="EnterpriseInstructor_Card_content">
                <img
                  className="EnterpriseInstructor_Card_image"
                  src={instructor.profile_image}
                  alt={instructor.name}
                />
                <div className="EnterpriseInstructor_Card_name">{instructor.name}</div>
                <div className="EnterpriseInstructor_Card_info">instructor info</div>
                <div className="EnterpriseInstructor_Card_bio">{instructor.about_details}</div>
               
                <button
                  className="EnterpriseInstructor_Card_button"
                  onClick={() => handleEdit(instructor.id)} // Handle edit button click
                >
                  Edit
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default EnterpriseInstructorComponent;
